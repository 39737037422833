<template>
    <div>
        <div class="body">
            <HeadBar></HeadBar>
        </div>
        <div>
            <AppMain></AppMain>
        </div>      
        <div class="foote">
             <Footer></Footer>
        </div>    
    </div>
</template>

<script>
import {HeadBar,AppMain,Footer} from './components'
    export default {
        components :{
               HeadBar,AppMain,Footer 
        },
        
    }
</script>

<style lang="scss" scoped>


.foote{
  
     margin-left: 18%;
   
}
</style>