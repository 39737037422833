<template>
  <div style="margin-top: -60px;">
     <router-view/>
  </div>
</template>

<script>


  export default {

    
  }
</script>


<style lang="scss">
.ql-align-right{
  text-align: right !important;
}
// *{margin-top: -60px;}
</style>