<template>
    <div>

      <div class="header" v-show="$route.meta.istrunz">

        <img src="../../../assets/1.png" alt="" class="yckj" style="width: 10%;" />
    <el-menu
           router
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          background-color="transparent"
          text-color="#fff"
          active-text-color="#fff"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/TemplatePage">模板</el-menu-item>
          <el-menu-item index="/CasePage">案例</el-menu-item>
          <el-menu-item index="/HelpPage">帮助</el-menu-item>
          <el-menu-item index="/AboutUs">关于我们</el-menu-item>
          <el-menu-item index="/DemoProgram">系统演示</el-menu-item>
        </el-menu>
        <img src="../../../assets/denglu.png" alt="" class="zc" @click="login">
      </div>
    </div>

</template>

<script>
    export default {
       methods: {
        login() {
          this.$router.push('login')
        }
       },
    }
</script>

<style lang="scss" scoped>

 .header {
      display: flex;
      justify-content: center;
      transform: translateY(80px);
      .yckj {
        transform: translateX(-220px);
      }
      .zc {
        transform: translateX(20px);
        transform: translateY(10px);
        width: 120px;
        height: 36px;
      }
    }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
    height: 1rem;
    line-height: 0.5rem;
    text-align: center;
  }
  .el-menu-item{
    padding: 0 50px;
  }
  .el-menu-item:hover {
    background-color: transparent !important;

}
</style>
