import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import scroll from 'vue-seamless-scroll'
import 'normalize.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import App from './App.vue';
import * as echarts from 'echarts'

// 分页组件
import Pagination from "@/components/Pagination";
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 富文本
import Editor from "@/components/Editor"

Vue.prototype.$echarts = echarts

Vue.use(ElementUI);
Vue.use(scroll);
Vue.component('Pagination', Pagination)
Vue.component('ImageUpload', ImageUpload)
Vue.component('Editor', Editor)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
