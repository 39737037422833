<!--富文本编辑器-->
<template>
	<div class="rich-text-editor-container" v-loading="loading">
		<quill-editor :content="content" :options="editorOptions" class="ql-editor" ref="myQuillEditor"
			@change="onEditorChange($event)">
		</quill-editor>
		<!--视频上传弹窗-->
		<div>
			<el-dialog :close-on-click-modal="false" width="50%" style="margin-top: 1px" title="视频上传"
				:visible.sync="videoForm.show" append-to-body>
				<el-tabs v-model="videoForm.activeName">
					<el-tab-pane label="添加视频链接" name="first">
						<el-input v-model="videoForm.videoLink" placeholder="请输入视频链接" clearable></el-input>
						<el-button type="primary" size="small" style="margin: 20px 0px 0px 0px "
							@click="insertVideoLink(videoForm.videoLink,'')">确认
						</el-button>
					</el-tab-pane>
					<el-tab-pane label="本地视频上传" name="second" v-if="dictValue =='0'">
						<el-upload v-loading="loading" style="text-align: center;" drag
							:action="uploadVideoConfig.uploadUrl" :headers="uploadVideoConfig.headers" accept="video/*"
							:name="uploadVideoConfig.name" :before-upload="onBeforeUploadVideo"
							:on-success="onSuccessVideo" :on-error="onErrorVideo" :multiple="false">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="el-upload__tip" slot="tip">只能上传MP4文件，且不超过{{uploadVideoConfig.maxSize}}M</div>
						</el-upload>
					</el-tab-pane>
				</el-tabs>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	// require styles
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import {
		quillEditor,
		Quill
	} from 'vue-quill-editor'
  import IframeBlot from './quill-iframe.js'
	// 这里引入修改过的video模块并注册
	import Video from './quill-video.js'
	Quill.register(Video, true)
  Quill.register(IframeBlot,true);
	import {
		listType
	} from "@/api/vote";
	// 设置title
	import {
		setQuillTitle
	} from './quill-title.js'
	// 工具栏
	const toolbarOptions = [
		["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
		["blockquote", "code-block"], // 引用  代码块
		[{
			list: "ordered"
		}, {
			list: "bullet"
		}], // 有序、无序列表
		[{
			indent: "-1"
		}, {
			indent: "+1"
		}], // 缩进
		[{
			size: ["small", false, "large", "huge"]
		}], // 字体大小
		[{
			header: [1, 2, 3, 4, 5, 6, false]
		}], // 标题
		[{
			color: []
		}, {
			background: []
		}], // 字体颜色、字体背景颜色
		[{
			align: []
		}], // 对齐方式
		["clean"], // 清除文本格式
		["link"], // 清除文本格式
		['video', 'image'] // 视频
	]
	export default {
		name: 'RichTextEditor',
		components: {
			quillEditor
		},
		props: {
			/* 编辑器的内容 */
			content: { // 返回的html片段
				type: String,
				default: ''
			},
			// 视频上传配置
			uploadVideoConfig: {
				type: Object,
				default () {
					return {
						uploadUrl: process.env.VUE_APP_LOGOUT_URL + "/common/upload", // 上传地址
						headers: {
							Authorization: "Bearer " + localStorage.getItem('access_token'),
						},
						maxSize: 99999, // 图片上传大小限制，默认不超过2M
						name: 'file' // 图片上传字段
					}
				}
			}
		},
		data() {
			let _self = this;
			return {
				dictValue:0,
				loading: false, // 加载loading
				editorOptions: {
					placeholder: '',
					theme: 'snow', // or 'bubble'
					modules: {
						toolbar: {
							container: toolbarOptions, // 工具栏
							handlers: {
								'video': () => {
									// 覆盖默认的上传视频，点击视频图标，显示弹窗
									_self.videoForm.show = true
								}
							}
						}
					}
				},
				// 视频上传变量
				videoForm: {
					show: false, // 显示插入视频链接弹框的标识
					videoLink: '',
					activeName: 'first'
				}
			}
		},
		mounted() {
			// 初始给编辑器设置title
			setQuillTitle()
			let queryParams = {
					pageNum: 1,
					pageSize: 10,
					dictType: "local_video",
				}
				listType(queryParams).then(res => {
					this.dictValue = res.rows[0].dictValue
				})
		},
		methods: {
			escape2Html(str) {
				var arrEntities = {
					'lt': '<',
					'gt': '>',
					'nbsp': ' ',
					'amp': '&',
					'quot': '"'
				};
				return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) {
					return arrEntities[t];
				});
			},
			// 文本编辑
			onEditorChange({
				quill,
				html,
				text
			}) {
				// console.log('editor changed:', quill, html, text)
				// console.log('html.replace(/<[^>]*>|/g:', html.replace(/<[^>]*>|/g))
				//this.$emit('update:content', html)
				//console.log("转换" + html.replace(/<[^>]*>|/g));
				//html =  html.replace(/<[^>]*>|/g)
				this.$emit('changes', html)
			},
			hideLoading() {
				this.loading = false
			},
			/** --------- 视频上传相关 start ---------  */
			insertVideoLink(videoLink, poster) {
				if (!videoLink) return this.$message.error('视频地址不能为空！')
				this.videoForm.show = false
				let quill = this.$refs['myQuillEditor'].quill
				// 获取富文本
				let range = quill.getSelection()
				// 获取光标位置：当编辑器中没有输入文本时，这里获取到的 range 为 null
				let index = range ? range.index : 0


            // <iframe frameborder="0" src="https://v.qq.com/txp/iframe/player.html?vid=w0046tr5rgl" allowFullScreen="true"></iframe>/
        // videoLink = videoLink.slice(videoLink.indexOf("https"), videoLink.indexOf(".html") + 5);
        // let pattern = /<iframe.*?src=["'](.+?)["'].*?>/gm;
        // videoLink = videoLink.match(pattern);




        const iframeRegex = /<iframe.*?src=["'](.+?)["'].*?>/gm;
        // const iframeHTML = "<iframe src='https://www.example.com/video.mp4'></iframe>";
        const match = iframeRegex.exec(videoLink);
        videoLink = match ? match[1] : '';

      console.log(videoLink,'videoLinkvideoLinkvideoLink')


				// 没有视频默认封面时，设置默认视频封面图片
				// 在光标所在位置 插入视频
				quill.insertEmbed(index, 'iframe', videoLink)
				// 调整光标到最后`
				quill.setSelection(index + 1)
			},
      insertVideoLink1(videoLink, poster) {
        if (!videoLink) return this.$message.error('视频地址不能为空！')
        this.videoForm.show = false
        let quill = this.$refs['myQuillEditor'].quill
        // 获取富文本
        let range = quill.getSelection()
        // 获取光标位置：当编辑器中没有输入文本时，这里获取到的 range 为 null
        let index = range ? range.index : 0


        // <iframe frameborder="0" src="https://v.qq.com/txp/iframe/player.html?vid=w0046tr5rgl" allowFullScreen="true"></iframe>/
        // videoLink = videoLink.slice(videoLink.indexOf("https"), videoLink.indexOf(".html") + 5);

        // 没有视频默认封面时，设置默认视频封面图片
        // 在光标所在位置 插入视频
        quill.insertEmbed(index, 'video', {
          url:videoLink
        })
        // 调整光标到最后
        quill.setSelection(index + 1)
      },
			// el-文件上传组件
			onBeforeUploadVideo(file) {
				this.loading = true
				let acceptArr = ['video/mp4']
				const isVideo = acceptArr.includes(file.type)
				const isLt1M = file.size / 1024 / 1024 < this.uploadVideoConfig.maxSize
				if (!isVideo) {
					this.hideLoading()
					this.$message.error('只能上传mp4格式文件!')
				}
				if (!isLt1M) {
					this.hideLoading()
					this.$message.error(`上传文件大小不能超过 ${this.uploadVideoConfig.maxSize}MB!`)
				}
				return isLt1M && isVideo
			},
			// 文件上传成功时的钩子
			onSuccessVideo(res) {
				this.hideLoading()
				if (res.code == 200) {
					this.insertVideoLink1(res.fileName, null)
				} else {
					this.$message.error(res.desc)
				}
			},
			// 文件上传失败时的钩子
			onErrorVideo() {
				this.hideLoading()
				this.$message.error('上传失败')
			},
			/**--------- 视频上传相关 end --------- */
		}
	}
</script>
<style lang='less'>
	.rich-text-editor-container .ql-container {
		height: 300px;
	}

	.rich-text-editor-container .ql-editor {
		padding: 0;
	}

	.rich-text-editor-container .ql-tooltip {
		left: 5px !important;
	}
	.ql-align-right{
		text-align: right;
	}
  iframe{
//    display: block;
//    width: 100%;
//    height: calc((100vw - 40px) * 9 / 16);
//    margin: 12px 0;
//margin-right: 10%;
  }
</style>
