import Video from "@/components/Editor/quill-video";
import { Quill } from "vue-quill-editor";
// 源码中是import直接倒入，这里要用Quill.import引入

const BlockEmbed = Quill.import('blots/block/embed');

class IframeBlot extends BlockEmbed {
    static create(value) {
        let node = super.create(value);
        node.setAttribute('src', value);
        node.setAttribute('frameborder', '0');
        node.setAttribute('allowfullscreen', true);
        return node;
    }

    static value(node) {
        return node.getAttribute('src');
    }
}

IframeBlot.blotName = 'iframe';
IframeBlot.tagName = 'iframe';
IframeBlot.className = 'ql-iframe';
export default IframeBlot
