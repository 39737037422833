
//引入
import {get,post,request} from './request';
//新增活动
export const  wxvoteInformation = (data)=>post('/wxvote/information',data)
//根据id查询活动
export const  wxvoteInformationId = (id)=>get('/wxvote/information/'+id)
//修改活动
export const  wxvoteInformationUpdate = (data)=>request('PUT','/wxvote/information/',data)

/***********************************************************选手配置****************************************************************/

// 新增选手
export const wxvotePlayer = (data)=>post('/wxvote/player',data)
// 获取选手详细信息
export const wxvotePlayerInfoById = (id)=>get('/wxvote/player', {id})

// 修改选手
export const wxvotePlayerUpdate = (data)=>request("PUT", '/wxvote/player',data)
// 删除选手
export const wxvotePlayerDelete = (id)=>request("DELETE", '/wxvote/player/'+id)
// 重置 选手编号
export const resetTheNumber = (basicInformationId)=>get( '/wxvote/player/resetTheNumber', {basicInformationId} )
// 恢复票数
export const updatePlayerRecover = (data) => request("PUT",'wxvote/player/updateRecover', data)
// 下载报名选手
export const exportsSign = (data) => post("/wxvote/player/export", data)


// 查询无分页选手分组列表
export const noPageListGroup = (data)=>get("/wxvote/group/noPageListGroup", data)
// 新增选手分组
export const wxvoteGroup = (data)=>post("/wxvote/group", data)
// 修改选手分组
export const wxvoteGroupUpdate = (data)=>request("PUT","/wxvote/group", data)
// 删除选手分组
export const wxvoteGroupDelete = (ids)=>request("DELETE","/wxvote/group/"+ ids)

/***********************************************************页面配置****************************************************************/

// 自定义设置查询
export const wxvoteInfo= (data) => get("/wxvote/configuration/list", data)

// 首页配置查询
export const  wxvoteHome = (vhId)=>get('/wxvote/home/'+vhId)
// 首页配置查询
export const  wxvoteHomeList = (data)=>get('/wxvote/home/list', data)
// 首页配置修改
export const wxvoteHomeUpdate = (data)=>request("PUT", '/wxvote/home',data)

// 内置颜色
export const wxvoteNoPageList = ()=>get('wxvote/color/noPageList')
// 页面漂浮物
export const wxvoteNoPageFloats = ()=>get('wxvote/floats/noPageList')
// 内置音乐
export const wxvoteMusic = (data) => get('wxvote/carousel/noPageList', data)
// 内置背景
export const wxvoteBackground = (data) => get('wxvote/background/list',data)

// 更改后台页面
export const editPlayersStoreById = (data) => post("/wxvote/player/editPlayersStoreById", data)


export const listType = (params) => get("/system/dict/data/list", params)
// 获取当前链接
export const informationSelectDomain = () => get("/wxvote/information/selectDomain")
