// 引入
import {get,post} from './request';
// 登陆
export const login= (login)=>post('/api/post/user/login',login)

// 查询显示图片
export const homeImag = (data)=>get('/wxvote/carousel/list', data)

// 查询公告详细
export const getNotice = (noticeId)=>get('/system/notice/' + noticeId)
