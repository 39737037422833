<template>
  <div class="footer" v-show="$route.meta.istrun">
    <div class="way">
      <div class="paas">
    <div>

      <img src="../../../assets/1.png" alt="" style="width: 100%;">
    </div>
        <div class="aq" style="color:#fff">安全稳定的一站式托管运营 投票平台</div>
      </div>
      <div class="way2">
        <div style="color:#fff" class="product">产品中心</div>
        <hr size="1px" class="fgx"/>
        <hr size="1px" class="fgx2" width="15px" color="#106ad6"/>
        <p style="color:#fff" class="aqq">图文投票</p>
        <p style="color:#fff" class="aq2">视频投票</p>
        <p style="color:#fff" class="aq2">关注投票</p>
        <p style="color:#fff" class="aq2">分组投票</p>
      </div>
      <div class="way3">
        <p style="color:#fff">专题投票</p>
        <p style="color:#fff">在线报名投票</p>
      </div>
      <div class="way4">
        <div style="color:#fff" class="product">联系我们</div>
        <hr width="72px" size="1px" class="fgx"/>
        <hr size="1px" class="fgx2" width="15px" color="#106ad6"/>
        <div class="kefu">
          <span style="color:#fff">客服热线:</span>
          <span style="color:#fff" class="phone"><div v-html="csHotline.noticeContent"></div> </span>
          <p style="color:#fff">地址：</p>
          <div v-html="address.noticeContent"></div>

        </div>
      </div>
        <div v-html="qrCode.noticeContent" class="code"> </div>
        <p style="color:#fff" class="gz">扫码关注我们</p>
    </div>
    <hr size="1px" color="#312c36"/>
    <div style="color:#fff" class="yuchen">Copyright(C)四川阿米云有限公司 版权所有</div>
  </div>
</template>

<script>
import {getNotice} from "@/api/home";

export default {
  data() {
    return {
      csHotline: {},

      address: {},

      qrCode: {}

    }
  },

  mounted() {
    this.init()

  },

  methods: {

    init() {
      this.getAnnouncementsText()
    },

    getAnnouncementsText() {
      //客服热线
      getNotice(22).then(res => {
        this.csHotline = res.data
      })

      // 地址
      getNotice(23).then(res => {
        this.address = res.data
      })

      // 二维码
      getNotice(42).then(res => {
        this.qrCode = res.data
      })
    },


  }
}
</script>

<style lang="scss" scoped>

 .footer{
    width: 1920px;
    background-color: #000;
    transform: translateX(-350px);

    .yuchen{
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
      padding-bottom: 30px;
    }
    .way{
      display: flex;
      justify-content: space-between;
      width: 1300px;
      height: 240px;
      margin-left: 16%;
      padding-top: 54px;
      .product{
        font-size: 18px;
        padding-bottom: 40px;
      }
      .paas{
        margin-top: 41px;
         text-align:left;
      }
      .aq{
        width: 169px;
        height: 38px;
        font-size: 14px;
        margin-top: 15px;
      }
    }
    .way2{
      .der{
        margin-top: -30px;
      }
      .aqq{
        margin-top: 40px;
      }
      .aq2{
      font-size: 14px;
      }
      .fgx{
        margin-top: -30px;
        margin-bottom: 40px;
      }
      .fgx2{
         margin-top: -41px;
         margin-left: 0px;
      }
    }
    .way3{
      width: 84px;
      height: 50px;
    font-size: 14px;
    text-align:left;
    margin-top: 80px;
    }
    .way4{
      font-size: 14px;
       text-align:left;
       .phone{
      font-weight:bold;
      font-size: 20px;
      margin-left: 10px;
       }
       .fgx{
        margin-left: -2px;
        margin-top: -30px;
        margin-bottom: 50px;
       }
       .fgx2{
         margin-top: -51px;
         margin-left: -2px;
      }
      .kefu{
        margin-top: 30px;
      }
    }
    .gz{
      font-size: 14px;
      margin-top: 220px;
      transform: translateX(-110px);
    }
  }
.code{
transform: translateY(-30px);
margin-right: -170px;
}
</style>
