import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomePage from '../views/HomePage.vue'
import layout from '../views/layout/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component:layout,
    children:[
      {
        path: '/',
        name: 'HomePage',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/HomePage.vue')

      },
      {
        path: '/TemplatePage',
        name: 'TemplatePage',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/TemplatePage.vue')
      },{
        path: '/TermsOfService',
        name: 'TermsOfService',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/TermsOfService.vue')
      },{
        path: '/AnnouncementsSearch',
        name: 'AnnouncementsSearch',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/AnnouncementsSearch.vue')
      },{
        path: '/ResetPassword',
        name: 'ResetPassword',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/ResetPassword.vue')
      },{
        path: '/DemoProgram',
        name: 'DemoProgram',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/DemoProgram.vue')
      },{
        path: '/test/PageSetup',
        name: 'PageSetupTest',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/PageSetup.vue')
      },{
        path: '/test/PlayerSet',
        name: 'PlayerSetTest',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/PlayerSet.vue')
      },{
        path: '/test/VoteManage',
        name: 'VoteManageTest',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/VoteManage.vue')
      },{
        path: '/WechatLogin',
        name: 'WechatLogin',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/WechatLogin.vue')
      },{
        path: '/WechatRegister',
        name: 'WechatRegister',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/WechatRegister.vue')
      },{
        path: '/BindingUser',
        name: 'BindingUser',
        meta:{
          istrunz:true,
        },
        component: () => import('../views/BindingUser.vue')
      },{
        path: '/CasePage',
        name: 'CasePage',
        meta:{
          istrun:true,
           istrunz:true,
        },
        component: () => import('../views/CasePage.vue')
      },{
        path: '/HelpPage',
        name: 'HelpPage',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/HelpPage.vue')
      },{
        path: '/ProblemCollect',
        name: 'ProblemCollect',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/ProblemCollect.vue')
      },{
        path: '/QuickSeat',
        name: 'QuickSeat',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/QuickSeat.vue')
      },{
        path: '/AboutUs',
        name: 'AboutUs',
        meta:{
          istrun:true,
          istrunz:true,
        },
        component: () => import('../views/AboutUs.vue')
      },  {
        path: '/login',
        name: 'login',
        meta:{

          istrunz:true,
        },
        component: () => import('../views/login.vue'),
      },
    ]

  },
  {
    path: '/ClientHeader',
    name: 'clientLayout',
    component: () => import('../views/client/layout/layout.vue'),
    children:[
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/client/user.vue')

      },
      {
        path: '/VoteTemplate',
        name: 'VoteTemplate',
        component: () => import('../views/client/VoteTemplate.vue')

      },
      {
        path: '/VoteManage',
        name: 'VoteManage',
        component: () => import('../views/client/VoteManage.vue')

      },{
        path: '/VotingTopics',
        name: 'VotingTopics',
        component: () => import('../views/client/VotingTopics.vue')

      },{
        path: '/FinanceCenter',
        name: 'FinanceCenter',
        component: () => import('../views/client/FinanceCenter.vue')

      },{
        path: '/BasicMessage',
        name: 'BasicMessage',
        component: () => import('../views/client/BasicMessage.vue')

      },{
        path: '/PlayerSet',
        name: 'PlayerSet',
        component: () => import('../views/client/PlayerSet.vue')

      },{
        path: '/PageSetup',
        name: 'PageSetup',
        component: () => import('../views/client/PageSetup.vue')

      },{
        path: '/PollRegulate',
        name: 'PollRegulate',
        component: () => import('../views/client/PollRegulate.vue')

      },{
        path: '/Bindwx',
        name: 'Bindwx',
        component: () => import('../views/client/Bindwx.vue')

      }
      ,{
        path: '/DiscussRegulate',
        name: 'DiscussRegulate',
        component: () => import('../views/client/DiscussRegulate.vue')

      },{
        path: '/EditRecord',
        name: 'EditRecord',
        component: () => import('../views/client/EditRecord.vue')

      },{
        path: '/ResultRanking',
        name: 'ResultRanking',
        component: () => import('../views/client/ResultRanking.vue')

      },{
        path: '/InsideVote',
        name: 'InsideVote',
        component: () => import('../views/client/InsideVote.vue')

      },{
        path: '/VoteStatistics',//投票统计
        name: 'VoteStatistics',
        component: () => import('../views/client/VoteStatistics.vue')

      },{
        path: '/ChoiceEdition',
        name: 'ChoiceEdition',
        component: () => import('../views/client/ChoiceEdition.vue')
      },{
        path: '/EditSubject',
        name: 'EditSubject',
        component: () => import('../views/client/EditSubject.vue')
      },{
        path: '/SpecialVote',
        name: 'SpecialVote',
        component: () => import('../views/client/SpecialVote.vue')
      },{
        path: '/AccountUpgrade',
        name: 'AccountUpgrade',
        component: () => import('../views/client/AccountUpgrade.vue')
      }
    ]

  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
