<template>
    <div>
        <router-view :key="key" />
    </div>
</template>

<script>
    export default {
        name:'appMain',
    computed: {
       key() {
            return this.$router.path
        }
    },
    }
</script>

<style lang="scss" scoped>

</style>