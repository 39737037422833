
//引入
import axios from "axios";
import {Message} from "element-ui";
axios.defaults.timeout = 60000;
axios.defaults.baseURL = process.env.VUE_APP_LOGOUT_URL;
import { tansParams, blobValidate } from "@/utils/ruoyi";
import { saveAs } from 'file-saver';
import errorCode from '@/utils/errorCode';
let loading = null;
let downloadLoadingInstance;


/*
 *请求前拦截
 *用于处理需要请求前的操作
 */
axios.interceptors.request.use(
    config => {
        // loading = Loading.service({
        //     text: "正在加载中......",
        //     fullscreen: true
        // });
        if (localStorage.getItem('access_token')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/*
 *请求响应拦截
 *用于处理数据返回后的操作
 */

axios.interceptors.response.use(req=>{
    if(req.status != 200){
        error(req.status,"服务器内部异常")
        return Promise.reject(req.data)
    }
    if(req.data.code != 200 ){
        error(req.data.code,req.data.msg)
        return Promise.reject(req.data)
    }
    return req.data
}
);


function error(code,msg){
    switch (code) {
        case 500:
            Message({
                type: "error",
                message: msg
            });
            break;
        case 404:
            Message({
                type: "error",
                message: "未找到远程服务器"
            });
            break;
        case 401:
            localStorage.clear();
            Message({
                type: "error",
                message: "用户登陆过期，请重新登陆"
            });
			 location.href = '/login';
            //这里应该要跳转到登录页，看需求实现
            break;
        // default:
        //     Message({
        //         type: "error",
        //         message: "数据异常"
        //     });
    }
}


// axios.interceptors.response.use(
//     response => {
//         return new Promise((resolve, reject) => {
//             //请求成功后关闭加载框
//             // if (loading) {
//             //     loading.close();
//             // }
//             const res = response.data;
//             if (res.err_code === 0) {
//                 resolve(res)
//             } else {
//                 reject(res)
//             }
//         })
//     },
//     error => {
//         console.log(error)
//         //请求成功后关闭加载框
//         // if (loading) {
//         //     loading.close();
//         // }
//         //断网处理或者请求超时
//         if (!error.response) {
//             //请求超时
//             if (error.message.includes("timeout")) {
//                 console.log("超时了");
//                 messages("error", "请求超时，请检查互联网连接");
//             } else {
//                 //断网，可以展示断网组件
//                 console.log("断网了");
//                 messages("error", "请检查网络是否已连接");
//             }
//             return;
//         }
//         const status = error.response.status;
//         switch (status) {
//             case 500:
//                 messages("error", "服务器内部错误");
//                 break;
//             case 404:
//                 messages(
//                     "error",
//                     "未找到远程服务器"
//                 );
//                 break;
//             case 401:
//                 localStorage.setItem('access_token', null);//更新token
//                 messages("warning", "用户登陆过期，请重新登陆");

//                 break;
//             case 400:
//                 messages("error", "数据异常");
//                 break;
//             default:
//                 messages("error", error.response.data.message);
//         }
//         return Promise.reject(error);
//     }
// );
/*
 *get方法，对应get请求
 *@param {String} url [请求的url地址]
 *@param {Object} params [请求时候携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}
/*
 *post方法，对应post请求
 *@param {String} url [请求的url地址]
 *@param {Object} params [请求时候携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 封装所有请求
 * @param methed
 * @param url
 * @param data
 * @param headers
 * @returns {Promise}
 */
export function request(methed, url, data = {}, headers) {
    return new Promise((resolve, reject) => {
        axios({
            method: methed || 'post',
            url: url,
            params: methed === 'get' ? data : '',
            data: methed !== 'get' ? data : '',
            headers: headers || {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err)
            })
    })
}

// /**
//  * excel导出请求
//  * @param url
//  * @param params
//  * @returns {Promise}
//  */
// export function postResponseFile(url, params) {
//     return axios({
//         url,
//         method: 'post',
//         data:params,
//         header: {
//             headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//         },
//         responseType: 'blob'	//指明返回格式
//     })
// }


// 通用下载方法
export function postResponseFile(url, params, filename, config) {
    return axios.post(url, params, {
        transformRequest: [(params) => { return tansParams(params) }],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
        ...config
    }).then(async (data) => {
        const isLogin = await blobValidate(data);
        console.log(isLogin)
        if (isLogin) {
            const blob = new Blob([data])
            saveAs(blob, filename)
        } else {
            const resText = await data.text();
            const rspObj = JSON.parse(resText);
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            Message.error(errMsg);
        }
    }).catch((data) => {
        const blob = new Blob([data])
        saveAs(blob, filename)
        // console.error(r)
        // Message.error('下载文件出现错误，请联系管理员！')
    })
}


